<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="Brand Name" prop="name">
                <a-input v-model="queryParam.name" placeholder="" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Status" prop="status">
                <a-select v-model="queryParam.status">
                  <a-select-option :key="1">Enable</a-select-option>
                  <a-select-option :key="0">Disable</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Business Type" prop="businessType">
                <a-select v-model="queryParam.businessType" placeholder="-All Business Type-">
                  <a-select-option :key="item.id" v-for="(item,index) in BusinessTypeList">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />Search</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />Clear All</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['cigarette:brand:add']">-->
<!--          <a-icon type="plus" />Add-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['cigarette:brand:edit']">-->
<!--          <a-icon type="edit" />Modify-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['cigarette:brand:remove']">-->
<!--          <a-icon type="delete" />Delete-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['cigarette:brand:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-row :gutter="[16,16]">
        <a-col :span="4" v-hasPermi="['cigarette:brand:add']">
          <div class="goodsDateAdd" @click="$refs.createForm.handleAdd()">
            <a-icon type="plus" class="goodsDateAdd-icon" style="font-size: 50px;" />
            <div class="goodsDateAdd-title">Create Brand</div>
          </div>
        </a-col>
        <a-col :span="4" v-for="(item,index) in list">
          <div class="goodsDateInfo" :style="[{
            background:item.brandStatus === 0 ? '#f8f8f8':'#fff'
          }]">
            <div class="goodsDateInfo-absolute">
              <a-icon type="play-circle" theme="twoTone"  @click.stop="changeStatus(item,1)" v-if="item.status === 1 && item.brandStatus === 0" style="font-size: 28px;" />
              <a-icon type="pause-circle" theme="twoTone" @click.stop="changeStatus(item,0)" v-if="item.status === 1 && item.brandStatus === 1" style="font-size: 28px;" />
              <a-icon type="check-circle" theme="twoTone" v-hasPermi="['cigarette:brand:audit']" @click.stop="auditModal(item)" v-if="item.status === 0" style="font-size: 28px;" />
              <!--              <a-icon type="eye" theme="twoTone" style="font-size: 28px;margin-left: 5px" />-->
              <a-icon type="edit" @click="$refs.createForm.handleUpdate(item, undefined)" v-hasPermi="['cigarette:brand:edit']" theme="twoTone" style="font-size: 28px;margin-left: 5px" />
            </div>
            <div class="goodsDateInfo-GoodsDate">
              <div class="goodsDateInfo-GoodsDate-flex">
                <img class="goodsDateInfo-GoodsDate-flex-img" :src="item.logo" />
<!--                <div class="goodsDateInfo-GoodsDate-flex-name">{{ item.name }}</div>-->
              </div>
            </div>
            <div class="goodsDateInfo-bottom">
              <div class="goodsDateInfo-bottom-flex">
                <a-button type="primary" @click="openBrandSku(item)" v-hasPermi="['cigarette:brandSku:look']">Sku</a-button>
                <a-button type="primary" @click="gotoUser(item)">User</a-button>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-empty :description="false" style="margin-top: 100px;margin-bottom: 100px" v-if="userInfo.roleList[0].roleKey == 'platform' && list.length === 0" />
<!--      <a-table-->
<!--        :loading="loading"-->
<!--        :size="tableSize"-->
<!--        rowKey="id"-->
<!--        :columns="columns"-->
<!--        :data-source="list"-->
<!--        :pagination="false"-->
<!--        :bordered="tableBordered"-->
<!--      >-->
<!--        <span slot="createTime" slot-scope="text, record">-->
<!--          {{ parseTime(record.createTime) }}-->
<!--        </span>-->
<!--        <span slot="operation" slot-scope="text, record">-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:brand:edit']">-->
<!--            <a-icon type="edit" />Modify-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['cigarette:brand:audit']" v-if="record.status == 0" />-->
<!--          <a @click="auditModal(record)" v-hasPermi="['cigarette:brand:audit']" v-if="record.status == 0" >-->
<!--            <a-icon type="audit" />Audit-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['cigarette:brandSku:look']" v-if="record.status == 1 && record.brandStatus == 1" />-->
<!--          <a @click="$refs.brandSku.show(record)" v-hasPermi="['cigarette:brandSku:look']"  v-if="record.status == 1 && record.brandStatus == 1">-->
<!--             <a-icon type="eye" />Sku List-->
<!--          </a>-->
<!--        </span>-->
<!--      </a-table>-->
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `${total} results`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />

      <BrandSku ref="brandSku" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageBrand, listBrand, delBrand, updateBrand} from '@/api/cigarette/brand'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {listConfig} from "@/api/cigarette/config";
import BrandSku from "../sku/popIndex"
import {updateSku} from "@/api/cigarette/goodSku";
export default {
  name: 'Brand',
  components: {
    CreateForm,
    BrandSku
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        logo: null,
        introduction: null,
        name: null,
        businessType: null,
        businessTypeName: null,
        socialMediaUrl: null,
        brandOfficalWebsite: null,
        status: null,
        reason: null,
        userId: null,
        brandStatus: null,
        pageNum: 1,
        pageSize: 10
      },
      BusinessTypeList:[],
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Brand Name',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: 'Business Type',
          dataIndex: 'businessTypeName',
          align: 'center'
        },
        {
          title: 'Social Media Url',
          dataIndex: 'socialMediaUrl',
          align: 'center'
        },
        {
          title: 'Brand Official Website',
          dataIndex: 'brandOfficalWebsite',
          align: 'center'
        },

        {
          title: 'Brand Introduction',
          dataIndex: 'introduction',
          align: 'center'
        },
        {
          title: 'Brand Logo',
          dataIndex: 'logo',
          align: 'center'
        },

        // {
        //   title: '业务类型关联{zb_public_config.id}',
        //   dataIndex: 'businessType',
        //   ellipsis: true,
        //   align: 'center'
        // },

        // {
        //   title: 'Status',
        //   dataIndex: 'status',
        //   align: 'center'
        // },
        // {
        //   title: '审核原因',
        //   dataIndex: 'reason',
        //   align: 'center'
        // },
        // {
        //   title: '用户id 关联{zb_user.id}',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Status',
          dataIndex: 'brandStatus',
          align: 'center',
          customRender(t,r) {
            if(t == 1 && r.status == 1) {
              return 'Enable';//一次性
            } else if(t==0 && r.status == 1) {
              return 'Disable';//电池
            } else if(r.status == 0) {
              return 'Pending Review';//电池
            }else if(r.status == 2) {
              return 'Disable';//电池
            }
          }
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    // console.log(this.$route.params.skuId)
    // this.queryParam.skuId = this.$route.params.skuId || ''
    this.getList()
    this.getListBusinessType();
  },
  computed: {
    userInfo() {
      return this.$store.getters.info;
    }
  },
  watch: {
  },
  methods: {
    gotoBrandSku(item) {
      // this.$router.push()
      this.$router.push({
        path: '/clientbrand/brandProduct',
        query:
          {
            skuId: item.id
          }
      })
    },
    gotoUser() {
      if(this.userInfo.roleList[0].roleKey == 'platform') {
        this.$router.push('/consumer/biz')
      } else {
        this.$router.push('/myUser/myUserList')
      }
    },
    openBrandSku(record) {

      if(record.status == 1 && record.brandStatus == 1) {
        // this.$refs.brandSku.show(record)
        if(this.userInfo.roleList[0].roleKey == 'platform') {
          this.$router.push({
            path: '/clientbrand/brandProduct',
            query:
              {
                brandId: record.id
              }
          })
        } else {
          this.$refs.brandSku.show(record)
        }
        // this.$router.push({
        //   path: '/clientbrand/brandProduct',
        //   query:
        //     {
        //       brandId: record.id
        //     }
        // })
      } else {
        this.$message.info('The brand has not been approved yet')
      }
    },
    getListBusinessType () {
      listConfig({
        type: 0,
        status: 1
      }).then(res => {
        if (res.success) {
          this.BusinessTypeList = res.data;
        }
      })
    },
    /** 查询新-用户品牌列表 */
    getList () {
      this.loading = true
     pageBrand(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        logo: undefined,
        introduction: undefined,
        name: undefined,
        businessType: undefined,
        businessTypeName: undefined,
        socialMediaUrl: undefined,
        brandOfficalWebsite: undefined,
        status: undefined,
        reason: undefined,
        userId: undefined,
        brandStatus: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    changeStatus(item,status) {

      let that = this;
      this.$confirm({
        title:status == 1 ?  'Are sure to enble this Brand?' : 'Are sure to Disable this Brand?',
        content: "",
        icon:"exclamation-circle",
        okText: "Yes",
        cancelText: "No",
        onOk () {
          let newItemData = JSON.parse(JSON.stringify(item));
          newItemData.brandStatus = status;
          return updateBrand(newItemData).then(res => {
            if(res.success) {
              that.$set(item,'brandStatus',status);
            }
          })
        },
        onCancel () {
        }
      })

    },
    auditModal(record) {
      let that = this;
      this.$confirm({
        title: 'Are sure to Pass this Brand?',
        content: "",
        icon:"exclamation-circle",
        okText: "Yes",
        cancelText: "No",
        onOk () {
          let updateBrandData = JSON.parse(JSON.stringify(record));
          updateBrandData.status = 1;
          updateBrandData.brandStatus = 1;
          return updateBrand(updateBrandData)
            .then(() => {
              that.getList()
            })
        },
        onCancel () {
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delBrand(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/user-brand/export', {
            ...that.queryParam
          }, `新-用户品牌_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style lang="less">
.goodsDateAdd {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 8px;
  flex-direction: column;
}
.goodsDateAdd:hover {
  border: 1px solid blue;

  .goodsDateAdd-icon {
    color:blue;
  }

  .goodsDateAdd-title {
    color: blue;
  }
}
.goodsDateInfo {
  position: relative;
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 8px;
  //flex-direction: column;
}

.goodsDateInfo-GoodsDate-flex-img {
  height: 200px;
  width: 80%;
}

.goodsDateInfo-GoodsDate-flex-name {
  width: 80%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.goodsDateInfo-absolute {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
  z-index: 2;
}
.goodsDateInfo-GoodsDate {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.goodsDateInfo-GoodsDate-flex {
  width: 100%;
  height: 100%;
  display: flex;
  //flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.goodsDateInfo-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  display: none;
}

.goodsDateInfo-bottom-flex {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}
.goodsDateInfo:hover {
  border: 1px solid blue;
  .goodsDateInfo-absolute {
    display: block;
  }

  .goodsDateInfo-bottom {
    display: block;
  }
}
</style>
