<template>
  <a-drawer width="55%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="Brand Name:" prop="name" >
        <a-input v-model="form.name" placeholder="" :maxLength="50" />
        <div style="display: flex;justify-content: flex-end">{{ ((form.name && form.name.length) || 0)}}/50</div>
      </a-form-model-item>
      <a-form-model-item label="Business Type:" prop="businessType" >
        <a-checkbox-group v-model="form.businessType">
          <a-checkbox :value="item.id" v-if="companyBusiness.some(i => i == item.id)" v-for="(item,index) in BusinessTypeList" :key="index">
            {{item.name }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Social Media Url" prop="socialMediaUrl" >
        <a-input v-model="form.socialMediaUrl" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="Brand Official Website:" prop="brandOfficalWebsite" >
        <a-input v-model="form.brandOfficalWebsite" placeholder="" />
      </a-form-model-item>
      <a-form-model-item label="Brand Introduction:" prop="introduction" >
        <a-input v-model="form.introduction" placeholder="" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="Brand Logo:" prop="logo" >
<!--        <a-input v-model="form.logo" placeholder="" />-->
        <a-upload
          name="file"
          :multiple="true"
          :show-upload-list="false"
          :action="tupianshangchuanDizhi"
          :headers="headers"
          @change="handleChange($event)"
        >
          <a-button type="primary" style="margin-right: 10px;">Upload Picture</a-button>Recommended size of picture:240 pixels * 240 pixels, supporting JPG and PNG formats
        </a-upload>

        <div style="display:flex;flex-wrap: wrap;margin-top: 10px;" v-if="form.logo">
          <div style="display: flex;align-items: center; justify-content: center">
            <img :src="form.logo"  style="width: 240px;height: 240px;" />
          </div>
        </div>
      </a-form-model-item>


<!--      <a-form-model-item label="业务类型名称" prop="businessTypeName" >-->
<!--        <a-input v-model="form.businessTypeName" placeholder="请输入业务类型名称" />-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="状态:0-待审核,1-审核通过,2-审核驳回" prop="status" >-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="审核原因" prop="reason" >-->
<!--        <a-input v-model="form.reason" placeholder="请输入审核原因" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="用户id 关联{zb_user.id}" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id 关联{zb_user.id}" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="品牌状态:0-禁用 1-开启" prop="brandStatus" >-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            Save
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBrand, addBrand, updateBrand } from '@/api/cigarette/brand'
import {listConfig} from "@/api/cigarette/config";
import {getBrandCompanyInfo} from "@/api/system/dept";
import {tupianshangchuanDizhi} from '@/utils/request';
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      tupianshangchuanDizhi:tupianshangchuanDizhi,
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        logo: null,

        introduction: null,

        name: null,

        businessType: null,

        businessTypeName: null,

        socialMediaUrl: null,

        brandOfficalWebsite: null,

        status: 0,

        reason: null,

        userId: null,

        brandStatus: 0

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [{ required: true, message: 'Brand Name cannot be empty', trigger: 'blur' }],
        businessType: [{ required: true, message: 'Business Type cannot be empty' }],
        socialMediaUrl: [{ required: true, message: 'Social Media Url cannot be empty', trigger: 'blur' }],
        brandOfficalWebsite: [{ required: true, message: 'Brand Official Website cannot be empty', trigger: 'blur' }],
        introduction: [{ required: true, message: 'Brand Introduction cannot be empty', trigger: 'blur' }],
        logo: [{ required: true, message: 'Brand Logo cannot be empty', trigger: 'blur' }],
      },
      BusinessTypeList:[],
      companyBusiness:[],
      headers:{}
    }
  },
  filters: {
  },
  created () {
    this.headers = {
      "Authorization" : "Bearer " + this.$store.getters.token
    }
    this.getListBusinessType();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    handleChange(e,item) {
      console.log(e)
      console.log(item);
      this.form.logo = e.file.response.data
      // e.file.response.data
    },
    getBrandCompany() {
      getBrandCompanyInfo().then(res => {
        if(res.data.business) {
          this.companyBusiness = res.data.business.split(',');
        }
        this.open = true
      })
    },
    getListBusinessType () {
      listConfig({
        type: 0,
        status: 1
      }).then(res => {
        if (res.success) {
          this.BusinessTypeList = res.data;
        }
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        logo: null,
        introduction: null,
        name: null,
        businessType: null,
        businessTypeName: null,
        socialMediaUrl: null,
        brandOfficalWebsite: null,
        status: 0,
        reason: null,
        userId: null,
        brandStatus: 0
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.getBrandCompany();

      this.formTitle = 'Create Brand'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBrand({"id":id}).then(response => {
        this.form = response.data;
        if(this.form.businessType) {
          this.form.businessType = this.form.businessType.split(',')
        }
        this.getBrandCompany();
        // this.open = true
        this.formTitle = 'Edit Brand Info'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      if(this.form.businessType) {
        console.log(this.form.businessType);
       let map = new Map();
       for (let item of this.BusinessTypeList) {
         if(!map.has(item.id)) {
           map.set(item.id,item)
         }
       }
       let value = [...map.values()].filter(item => this.form.businessType.includes(item.id));
        this.form.businessTypeName = value.map(i => i.name).join(',');
        this.form.businessType = this.form.businessType.join(',')
       // console.log(value)
      }
      // return;
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBrand(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBrand(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
